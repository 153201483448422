@import "custom";

.logo {
    filter: drop-shadow(0 0 15px $blue-600);
}

#areas-carousel {
    .carousel-item {
        @extend .bg-gray-800;
    }

    .carousel-item .carousel-img {
        height: 300px;
    }
}

@include media-breakpoint-down(md) {
    #areas-carousel {
        .carousel-item .carousel-img {
            height: 150px;
        }
    }
}

.environmental-page, .about-us-page {
    blockquote {
        @extend .fst-italic;
        @extend .bg-gray-800;
        @extend .fs-6;
        @extend .p-3;
        @extend .rounded-2;
        width: fit-content;
        

        ol {
            width: fit-content;
            @extend .mb-0;
        }
    }
}

// https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
.youtube {
    position: relative;
    padding-bottom: 28.25%;
    height: 0;
    @extend .mb-4;
}

.youtube iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.form-control-dark:focus {
    border-color: $white;
    box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25);
}